








































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter, RootRouter } from "@/utils/routePathContsant";
import DetailTag from "@/components/UIComponents/DetailTag.vue";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
  OwnershipModule as ownershipStore,
} from "@/store/modules";
import { District } from "@/store/models/meta";
import helpers from "@/utils/helpers";

@Component({
  components: {
    Input,
    Button,
    Form,
    DetailTag,
  },
})
export default class Owner extends Vue {
  isCreate: boolean = true;
  municipality: any[] = [];
  districts: District[] = [];
  remarks: string = "";
  public centerDialogVisible = false;
  public centerRejectDialogVisible = false;
  public centerDeleteDialogVisible = false;
  get RootRouter() {
    return RootRouter;
  }
  get AdminRouter() {
    return AdminRouter;
  }

  get ownership() {
    let a = ownershipStore.ownership;

    return ownershipStore.ownership;
  }

  get profile() {
    return ownershipStore.profile;
  }

  get approvalStatusBadgeType() {
    if (this.ownership && this.ownership.profile) {
      const status = this.ownership.profile.approvalStatus;
      if (status == "Approved") {
        return "success";
      } else if (status == "New") {
        return "primary";
      } else if (status == "Pending") {
        return "danger";
      } else if (status == "Reapplied") {
        return "primary";
      }
    }
  }

  handleChange(file: any, fileList: any) {
    let vm = this;
    vm.ownership.profile.photoId = fileList[0].raw;
  }

  handleChange1(file: any, fileList: any) {
    let vm = this;
    vm.ownership.vehicleOwner.companyCertificate = fileList[0].raw;
  }

  handleChange2(file: any, fileList: any) {
    let vm = this;
    vm.ownership.vehicleOwner.panCertificate = fileList[0].raw;
  }

  handleChange3(file: any, fileList: any) {
    let vm = this;
    vm.ownership.vehicleOwner.vatCertificate = fileList[0].raw;
  }

  approveForm() {
    let vm = this;
    this.$confirm(`Are you sure you want to Approve?`)
      .then((_) => {
        ownershipStore.approveOwner(this.ownership.id);
        this.$snotify.success("Approved Successfully");
        this.$router.push(AdminRouter.VehicleOwnerList);
      })
      .catch((_) => {});
  }

  async submit() {
    // let isValid = await this.$validator.validateAll();
    // if (!isValid) {
    //   return;
    // }
    let vm = this;
    commonstore.showLoading();
    const id = vm.$route.query["id"];
    await ownershipStore.approveOwner(id);
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.VehicleOwnerList);
  }

  async deleteOwner() {
    let vm = this;
    commonstore.showLoading();
    const id = vm.$route.query["id"];

    if (this.ownership.id) {
      // Remove File Fields without file
      helpers.removeFilesForPatch([
        { property: this.ownership.profile, fieldName: "photoId" },
        {
          property: this.ownership.vehicleOwner,
          fieldName: "companyCertificate",
        },
        { property: this.ownership.vehicleOwner, fieldName: "panCertificate" },
        { property: this.ownership.vehicleOwner, fieldName: "vatCertificate" },
      ]);
    }
    await ownershipStore.deleteOwnership();
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.VehicleOwnerList);
  }

  async onHold() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let vm = this;
      this.centerRejectDialogVisible = false;
      commonstore.showLoading();
      const id = vm.$route.query["id"];
      await ownershipStore.holdOwner({ id: id, remarks: this.remarks });
      this.$snotify.warning("Application was holded");
      commonstore.hideLoading();
      this.remarks = "";
      // this.$router.push(AdminRouter.VehicleOwnerList);
    }
  }

  async cancelForm() {
    this.$router.push(AdminRouter.VehicleOwnerList);
  }

  async created() {
    let vm = this;
    await metaStore.loadProvince();
    await metaStore.loadDis();
    const id = vm.$route.query["id"];
    if (id) {
      vm.isCreate = false;

      await ownershipStore.getVehicleOwnerById(id);
    }
    if (this.ownership.profile.province) {
      this.districts = await metaStore.loadDistrict(
        this.ownership.profile.province
      );
    }

    if (this.ownership.profile.district) {
      this.municipality = await metaStore.loadMuncipality(
        this.ownership.profile.district
      );
    }
  }

  async onProvinceChange(e: any) {
    this.districts = await metaStore.loadDistrict(e);
  }

  async onDistrictChange(e: any) {
    this.municipality = await metaStore.loadMuncipality(e);
  }
  get province() {
    return metaStore.provinceList;
  }
  get districtList() {
    return metaStore.districtL;
  }

  mounted() {}
  updated() {}
}
